export default {
  namespaced: true,
  state: {
    roles: [],
  },
  getters: {
    can: state => givenPermissions => {
      let grantedCount = 0
      state.roles.forEach(role => {
        role.permissions.forEach(permission => {
          if (givenPermissions.includes(permission.name)) {
            grantedCount += 1
          }
        })
      })

      return grantedCount > 0
    },
  },
  mutations: {
    setUserCan(state, payload) {
      state.roles = payload
    },
  },
}
