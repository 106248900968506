import {
  mdiAccountOutline,
  mdiCalendarClockOutline,
  mdiCarOutline,
  mdiClipboardFileOutline,
  mdiCogOutline,
  mdiDeleteOutline,
  mdiHomeOutline,
  mdiProgressWrench,
  mdiShieldLockOutline,
  mdiViewList,
} from '@mdi/js'

export default [
  {
    subheader: 'APPS & PAGES',
  },
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'home',
    permissions: ['view dashboard'],
  },
  {
    title: 'Appointments',
    icon: mdiCalendarClockOutline,
    to: 'appointments',
    badge: localStorage.getItem('pendingAppointmentsCount'), // store.getters['app/getPendingAppointmentsCount'],
    badgeColor: 'warning',
    permissions: ['view appointments'],
  },
  {
    title: 'Job Orders',
    icon: mdiClipboardFileOutline,
    permissions: ['view job orders', 'create job orders'],
    children: [
      {
        title: 'List',
        to: 'job-orders',
        permissions: ['view job orders'],
      },
      {
        title: 'Create',
        to: 'job-orders-create',
        permissions: ['create job orders'],
      },
    ],
  },
  {
    title: 'Pre-Repairs',
    icon: mdiProgressWrench,
    permissions: ['view pre-repairs', 'create pre-repairs'],
    children: [
      {
        title: 'List',
        to: 'pre-repairs',
        permissions: ['view pre-repairs'],
      },
      {
        title: 'Create',
        to: 'pre-repairs-create',
        permissions: ['create pre-repairs'],
      },
    ],
  },
  {
    title: 'Vehicles',
    icon: mdiCarOutline,
    permissions: ['view vehicles'],
    children: [
      {
        title: 'List',
        to: { name: 'vehicles' },
        permissions: ['view vehicles'],
      },
      {
        title: 'Create',
        to: 'vehicles-create',
        permissions: ['create vehicles'],
      },
      {
        title: 'Makes',
        to: { name: 'vehicles-makes' },
        permissions: ['view vehicles'],
      },
      {
        title: 'Models',
        to: { name: 'vehicles-models' },
        permissions: ['view vehicles'],
      },
      {
        title: 'Types',
        to: { name: 'vehicles-types' },
        permissions: ['view vehicles'],
      },
    ],
  },
  {
    title: 'Inventory',
    icon: mdiViewList,
    to: 'inventory',
    permissions: ['view inventory'],
  },
  {
    title: 'Services',
    icon: mdiCogOutline,
    to: 'services',
    permissions: ['view services'],
  },

  {
    title: 'Employees',
    icon: mdiAccountOutline,
    to: 'employees',
    permissions: ['view employees'],
  },
  {
    title: 'Waste Reports',
    icon: mdiDeleteOutline,
    to: 'waste-reports',
    permissions: ['view waste reports'],
  },
  {
    title: 'Role-Permission',
    icon: mdiShieldLockOutline,
    to: 'role-permission',
    permissions: ['role-permission'],
  },
]
