import axios from '@axios'

export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    pendingAppointmentsCount: '',
  },
  getters: {
    getPendingAppointmentsCount(state) {
      return state.pendingAppointmentsCount
    },
  },
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    setPendingAppointmentsCount(state, payload) {
      state.pendingAppointmentsCount = payload
    },
  },
  actions: {
    getPendingAppointmentsCount({ commit }) {
      return new Promise(resolve => {
        axios.get('/appointments/pending-counts').then(response => {
          resolve(response)
          commit('setPendingAppointmentsCount', response.data)
          localStorage.setItem('pendingAppointmentsCount', response.data)
        })
      })
    },
  },
}
