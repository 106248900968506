import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))

      if (userData !== null) return { name: 'home' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/job-orders/list',
    name: 'job-orders',
    component: () => import('@/views/job-orders/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/job-orders/create',
    name: 'job-orders-create',
    component: () => import('@/views/job-orders/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/job-orders/edit/:id',
    name: 'job-orders-edit',
    component: () => import('@/views/job-orders/Create.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'job-orders',
    },
  },
  {
    path: '/pre-repairs/list',
    name: 'pre-repairs',
    component: () => import('@/views/pre-repairs/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pre-repairs/create',
    name: 'pre-repairs-create',
    component: () => import('@/views/pre-repairs/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pre-repairs/edit/:id',
    name: 'pre-repairs-edit',
    component: () => import('@/views/pre-repairs/Create.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'pre-repairs',
    },
  },
  {
    path: '/appointments',
    name: 'appointments',
    component: () => import('@/views/appointments/Appointments.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/appointments/create',
    name: 'appointments-create',
    component: () => import('@/views/appointments/Editor.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/appointments/edit/:id',
    name: 'appointments-edit',
    component: () => import('@/views/appointments/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'appointments',
    },
  },
  {
    path: '/vehicles/list',
    name: 'vehicles',
    component: () => import('@/views/vehicles/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/vehicles/create',
    name: 'vehicles-create',
    component: () => import('@/views/vehicles/Editor.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/vehicles/edit/:id',
    name: 'vehicles-edit',
    component: () => import('@/views/vehicles/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'vehicles',
    },
  },
  {
    path: '/vehicles/view/:id',
    name: 'vehicles-view',
    component: () => import('@/views/vehicles/Vehicle.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'vehicles',
    },
  },
  {
    path: '/vehicles/makes/list/',
    name: 'vehicles-makes',
    component: () => import('@/views/vehicles/Makes.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/vehicles/models',
    name: 'vehicles-models',
    component: () => import('@/views/vehicles/Models.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/vehicles/types',
    name: 'vehicles-types',
    component: () => import('@/views/vehicles/Types.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('@/views/parts/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/services/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/employees',
    name: 'employees',
    component: () => import('@/views/employees/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/employees/create',
    name: 'employees-create',
    component: () => import('@/views/employees/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'employees',
    },
  },
  {
    path: '/employees/edit/:id',
    name: 'employees-edit',
    component: () => import('@/views/employees/Editor.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'employees',
    },
  },
  {
    path: '/waste-reports',
    name: 'waste-reports',
    component: () => import('@/views/waste-reports/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/waste-reports/create',
    name: 'waste-reports-create',
    component: () => import('@/views/waste-reports/Create.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/waste-reports/edit/:id',
    name: 'waste-reports-edit',
    component: () => import('@/views/waste-reports/Create.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'waste-reports',
    },
  },
  {
    path: '/role-permission',
    name: 'role-permission',
    component: () => import('@/views/role-permission/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const isLoggedIn = localStorage.getItem('accessToken') !== null

  if (!to.meta.redirectIfLoggedIn) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
